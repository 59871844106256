import React from "react";
import styled from "styled-components";
import {
  Button,
  Label,
  Switch,
  Text,
  TextArea,
  Toaster,
  Position,
} from "@blueprintjs/core";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  display: block;
  font-size: 2em;
  font-weight: 200;
  letter-spacing: 0.1em;
  margin-top: 0.5em;
`;

const Summary = styled(Text)`
  padding: 10px;
  max-width: 90%;
  white-space: pre-wrap;
`;

const SummaryCentered = styled(Summary)`
  text-align: center;
`;

const TextEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  width: 60%;
`;

const TextEntry = styled(TextArea)`
  width: 100%;
  height: 200px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: space-around;
`;

const AlternatingCapsContainer = styled.div`
  margin: 20px 0;
  width: 60vw;
  min-height: 200px;
  background-color: #f0f0f0;
  border: 1px solid #f9f9f9;
`;

export const AppToaster = Toaster.create({
  position: Position.TOP,
});

const alternateCaps = (text: string, startPosition: number) => {
  let prevCharCapitalized = startPosition === 1;

  return text
    .toLowerCase()
    .split("")
    .map((char, i) => {
      if (
        char.match(
          /\s|,|!|\.|\?|-|:|;|"|'|@|#|\$|%|\^|&|\*|\(|\)|_|\{|\}|\[|\]|\+|=|[0-9]/g
        )
      ) {
        return char;
      }

      if (prevCharCapitalized) {
        prevCharCapitalized = false;
        return char;
      } else {
        prevCharCapitalized = true;
        return char.toUpperCase();
      }
    })
    .reduce((a, e) => a + e, "");
};

function App() {
  const [startWithCapitalLetter, setStartWithCapitalLetter] = React.useState(
    false
  );
  const [hasClaps, setHasClaps] = React.useState(false);
  const [enteredText, setEnteredText] = React.useState("");
  const [alternatingCapsText, setAlternatingCapsText] = React.useState("");
  const clapsHandler = React.useCallback(
    (hasClaps: boolean) => {
      hasClaps
        ? setAlternatingCapsText(alternatingCapsText.replace(/ /g, " 👏 "))
        : setAlternatingCapsText(alternatingCapsText.replace(/ 👏 /g, " "));
    },
    [alternatingCapsText]
  );
  const alternatingCapsHandler = React.useCallback(
    (text: string, startWithCapitalLetter: boolean) => {
      const newText = startWithCapitalLetter
        ? alternateCaps(text, 0)
        : alternateCaps(text, 1);

      hasClaps
        ? setAlternatingCapsText(newText.replace(/ |$/g, " 👏 "))
        : setAlternatingCapsText(newText.replace(/ 👏|$/g, ""));
    },
    [hasClaps]
  );
  const copyToClipboard = React.useCallback(() => {
    const textField = document.createElement("textarea");
    textField.innerText = alternatingCapsText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    AppToaster.show({
      intent: "success",
      message: "Copied text to clipboard.",
    });
  }, [alternatingCapsText]);
  const copyToClipboardMouseHandler = React.useCallback(
    (event: React.MouseEvent) => {
      copyToClipboard();
    },
    [copyToClipboard]
  );
  const resetHandler = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setEnteredText("");
    setAlternatingCapsText("");
    setStartWithCapitalLetter(false);
    setHasClaps(false);
  }, []);

  document.onkeydown = React.useCallback(
    function (this: GlobalEventHandlers, ev: KeyboardEvent) {
      if (ev.keyCode === 67 && (ev.ctrlKey || ev.metaKey)) {
        copyToClipboard();
      }
    },
    [copyToClipboard]
  );

  return (
    <Container>
      <Title>aLtErNaTiNg CaPs</Title>
      <SummaryCentered>
        Easy way to generate the super passive aggressive text known as
        <em> aLtErNaTiNg CaPs</em>, especially meant for times when you are
        trying to prove your point through mockery.
      </SummaryCentered>
      <TextEntryContainer>
        <Label>
          Type/paste some text below:
          <TextEntry
            autoFocus={true}
            value={enteredText}
            onChange={(event) => {
              const newValue = event.target.value;
              setEnteredText(newValue);
              alternatingCapsHandler(newValue, startWithCapitalLetter);
            }}
          />
        </Label>
        <Switch
          checked={startWithCapitalLetter}
          onChange={() => {
            const newValue = !startWithCapitalLetter;
            setStartWithCapitalLetter(newValue);
            if (enteredText) {
              alternatingCapsHandler(enteredText, newValue);
            }
          }}
          label="Start with capital letter"
        />
        <Switch
          checked={hasClaps}
          onChange={() => {
            const newValue = !hasClaps;
            setHasClaps(newValue);
            if (enteredText) {
              clapsHandler(newValue);
            }
          }}
          label="Add claps"
        />
      </TextEntryContainer>
      <AlternatingCapsContainer>
        <Summary>{alternatingCapsText}</Summary>
      </AlternatingCapsContainer>
      <ActionContainer>
        <Button
          intent="primary"
          text="Copy text to clipboard"
          onClick={copyToClipboardMouseHandler}
        />
        <Button
          text="Reset"
          onClick={resetHandler}
          style={{ marginLeft: "20px" }}
        />
      </ActionContainer>
      <Summary>
        You can also press <code>ctrl + c</code> or <code>cmd + c</code> to copy
        the text to the clipboard.
      </Summary>
    </Container>
  );
}

export default App;
